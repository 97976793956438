'use client'
import Button from '@/components/button'
import { IGif } from '@giphy/js-types'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import useWindowSize from 'react-use/lib/useWindowSize'
import TagPills from 'ui/src/components/tag-pill'
import { EllipsisIcon, TapAndHoldIcon } from 'ui/src/icons/utility'
import Modal from './modal'
import { Container, CopyWrapper, Footer, Gif, modalPadding } from './modal-sc'
import ReportButtonManager from './report-gif'

const MoreInfo = ({ gif, iconSize, children }: { iconSize: number; gif: IGif; children?: React.ReactNode }) => {
    const [showPortal, setShowPortal] = useState(false)
    const [reportPlatform, setReportPlatform] = useState<string | undefined>(undefined)
    const { width } = useWindowSize()
    const tags = gif.tags?.filter((tag) => !tag.startsWith('giphy'))

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const report = params.get('report')
        if (report) {
            setShowPortal(true)
            setReportPlatform(report as string)
        }
    }, [gif])

    return (
        <>
            <div
                onClick={() => {
                    setShowPortal(true)
                }}
            >
                <EllipsisIcon width={iconSize} height={iconSize} />
            </div>
            {showPortal &&
                createPortal(
                    <Modal onClose={() => setShowPortal(false)}>
                        <Container>
                            <CopyWrapper>
                                <Gif src={gif.images.original.url} alt={gif.title} width={width - modalPadding} />
                                <h4 className="flex align-middle">
                                    <TapAndHoldIcon
                                        width={iconSize}
                                        height={iconSize}
                                        className="inline-block align-middle"
                                    />
                                    Tap and Hold to Copy {gif.is_sticker ? 'Sticker' : 'GIF'}
                                </h4>
                            </CopyWrapper>
                            <Footer>
                                {tags?.length > 0 && (
                                    <div className="w-full space-x-1.5 overflow-auto whitespace-nowrap">
                                        {tags.map((tag) => (
                                            <Link
                                                href={`/explore/${encodeURIComponent(tag.replace(/ /g, '-'))}`}
                                                key={tag}
                                            >
                                                <TagPills text={`#${tag}`} />
                                            </Link>
                                        ))}
                                    </div>
                                )}
                                {children}
                                <ReportButtonManager gif={gif} reportPlatform={reportPlatform}>
                                    <Button className="bg-giphyDarkGrey w-full">Report Content</Button>
                                </ReportButtonManager>
                            </Footer>
                        </Container>
                    </Modal>,
                    document.body,
                    `${gif.id}-info`
                )}
        </>
    )
}
export default MoreInfo
