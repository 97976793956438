'use client'
import { IGif } from '@giphy/js-types'
import { ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import FlagForm from './flag-form'

type Props = { gif: IGif; children: ReactNode; className?: string; reportPlatform?: string }
function ReportButtonManager({ gif, children, className, reportPlatform }: Props) {
    const [showFlagForm, setShowFlagForm] = useState(false)
    const [scrollY, setScrollY] = useState(0)
    useEffect(() => {
        if (reportPlatform) {
            setShowFlagForm(true)
        }
    }, [reportPlatform])

    return (
        <>
            <div
                className={className}
                onClick={() => {
                    setShowFlagForm(true)
                }}
            >
                {children}
            </div>
            {showFlagForm &&
                createPortal(
                    <div
                        className="bg-giphyDarkestGrey z-modal fixed inset-0 flex flex-col bg-opacity-90 px-5"
                        onScroll={(e) => {
                            setScrollY(e.currentTarget.scrollTop)
                        }}
                        onClick={() => {
                            setShowFlagForm(false)
                        }}
                    >
                        <FlagForm
                            scrollY={scrollY}
                            gif={gif.id as string}
                            toggleForm={() => setShowFlagForm(false)}
                            platform={reportPlatform || 'web'}
                        />
                    </div>,
                    document.body,
                    `${gif.id}-flag`
                )}
        </>
    )
}

export default ReportButtonManager
