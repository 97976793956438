'use client'
import { GifPanelContext } from '@/app/components/detail/gif-panel-context-manager'
import CloseIcon from '@/app/icons/close'
import Button from '@/components/button'
import Toggle from '@/components/toggle'
import { cn } from '@/util/cn'
import { IGif } from '@giphy/js-types'
import { getGifHeight, getGifWidth } from '@giphy/js-util'
import { useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { modalBackgroundClassName, modalBoxClassName } from './class-names'

type Props = { gif: IGif }

const EmbedPanel = ({ gif }: Props) => {
    const { panel, setPanel } = useContext(GifPanelContext)
    const mediaType = gif.is_sticker ? 'Sticker' : 'Gif'
    const [isResponsive, setIsResponsive] = useState(false)
    const embedCode = getEmbedCode(gif, isResponsive)
    const options = [{ label: 'Off' }, { label: 'On' }]
    const [success, setSuccess] = useState(false)
    useEffect(() => {
        let timeout: NodeJS.Timeout
        if (success) {
            timeout = setTimeout(() => {
                setSuccess(false)
            }, 2000)
        }
        return () => clearTimeout(timeout)
    }, [success])
    return panel === 'embed'
        ? createPortal(
              <div
                  className={modalBackgroundClassName}
                  onClick={() => {
                      setPanel('none')
                  }}
              >
                  <div
                      className={cn(modalBoxClassName, 'w-[560px] p-6')}
                      onClick={(e) => {
                          e.stopPropagation()
                      }}
                  >
                      <div className="border-giphyCharcoal mb-4 flex flex-1 justify-between border-b border-solid pb-2 font-bold">
                          <h4 className={`text-giphyWhite text-xl`}>{`Embed this ${mediaType}`}</h4>
                          <div onClick={() => setPanel('none')}>
                              <CloseIcon height={18} className="fill-giphyWhite cursor-pointer" />
                          </div>
                      </div>
                      <div className="mb-2 flex h-full flex-1 flex-col justify-center gap-4">
                          <p>
                              Want to embed this {mediaType} on your website or blog? Just drop in the embed code below
                              and you&apos;re done!
                          </p>
                          <section>
                              <h4>Responsive</h4>
                              <Toggle
                                  className="w-[250px]"
                                  index={0}
                                  options={options}
                                  onClick={(index: number) => {
                                      setIsResponsive(index === 1)
                                  }}
                              />
                          </section>
                          <section>
                              <h4>Embed Code</h4>
                              <div className="flex items-center">
                                  <input type="text" className="h-10 flex-1 rounded-l p-1" value={embedCode} readOnly />
                                  <Button
                                      className={cn(
                                          'h-[40px] w-[120px] cursor-pointer rounded-r-md transition-all',
                                          success && 'bg-gradient-secondary'
                                      )}
                                      onClick={() => {
                                          // doesn't work on http, but does work on https
                                          navigator.clipboard?.writeText(embedCode)
                                          setSuccess(true)
                                      }}
                                  >
                                      {success ? 'Copied' : 'Copy Code'}
                                  </Button>
                              </div>
                          </section>
                      </div>
                  </div>
              </div>,
              document.body,
              'gif-embed-modal'
          )
        : null
}

export default EmbedPanel

const EMBED_SIZE = 480

export const getEmbedCode = (gif: IGif, isResponsive = false) => {
    const {
        url,
        images: { original },
    } = gif
    let width = EMBED_SIZE
    let height = getGifHeight(gif, width)
    if (original.width < original.height) {
        height = EMBED_SIZE
        width = getGifWidth(gif, height)
    }
    const ratio = height / width
    const params = {
        src: gif.embed_url,
        width: isResponsive ? '100%' : Math.round(width),
        height: isResponsive ? '100%' : Math.round(height),
        style: isResponsive ? 'position:absolute' : '',
        frameBorder: '0',
        class: 'giphy-embed',
    }

    let code = isResponsive
        ? `<div style="width:100%;height:0;padding-bottom:${Math.round(ratio * 100)}%;position:relative;">`
        : ''
    code += `<iframe src="${params.src}" width="${params.width}" height="${params.height}" style="${params.style}" frameBorder="${params.frameBorder}" class="${params.class}" allowFullScreen></iframe>`
    code += isResponsive ? '</div>' : ''
    code += `<p><a href="${url}">via GIPHY</a></p>`

    return code
}
