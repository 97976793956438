import { SVGAttributes } from 'react'

const id = 'edit-icon'
const EditIcon = (props: SVGAttributes<SVGElement>) => (
    <svg fill={props.color || 'white'} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
        <defs>
            <linearGradient x1="0%" y1="10%" x2="0%" y2="90%" id={props.id || id}>
                <stop id="gradient-stop-1" offset="0%" stopColor="var(--stop-color-1)" />
                <stop id="gradient-stop-2" offset="100%" stopColor="var(--stop-color-2)" />
            </linearGradient>
        </defs>
        <path
            fill={props.className?.indexOf('--stop-color-1') === -1 ? '' : `url(#${props.id || id})`}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5001 6.25L19.6289 4.12109C19.8763 3.8737 20 3.5791 20 3.2373C20 2.89551 19.8763 2.5944 19.6289 2.33398L17.6661 0.371093C17.4057 0.123697 17.1046 0 16.7628 0C16.421 0 16.1264 0.123697 15.879 0.371093L13.7501 2.5L17.5001 6.25ZM0.42032 20C0.478913 20 0.530995 19.9935 0.576567 19.9805L5.81084 17.8906C5.86292 17.8711 5.90849 17.8418 5.94755 17.8027L16.2501 7.5L12.5002 3.75L2.19763 14.0527C2.15857 14.0918 2.12927 14.1374 2.10974 14.1895L0.0199378 19.4238C-0.0256342 19.6191 0.00691702 19.7721 0.117592 19.8828C0.215246 19.9609 0.316155 20 0.42032 20Z"
        />
    </svg>
)
export default EditIcon
